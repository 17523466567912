import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.widget.traffic');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ApexCharts from 'apexcharts';

@Component({
    selector: 'wiz-portal-kreonetadm-widget-traffic',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.kreonetadm.widget.traffic/view.scss */
.test {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.test .card {
  flex: 1;
  max-width: 600px;
}
.test .card .card-header {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.test .card .card-body {
  padding: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 30px;
}`],
})
export class PortalKreonetadmWidgetTrafficComponent implements OnInit {
    @ViewChild('chart1')
    public element1: ElementRef;

    @ViewChild('chart2')
    public element2: ElementRef;

    @ViewChild('chart3')
    public element3: ElementRef;

    @ViewChild('chart4')
    public element4: ElementRef;

    public traffics: any = {};

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        const { code, data } = await wiz.call("load");
        this.traffics = data;

        let color1 = ['#008FFB', '#A3DAFF'];
        let color2 = ['#B400FF', '#F0C1FF'];
        await this.render(this.element1, [
            { name: '트래픽', data: this.traffics.daily_traffic.count }
        ], this.traffics.daily_traffic.date, '날짜(월-일)', color1);
        await this.render(this.element2, [
            { name: '접속자', data: this.traffics.daily_users.count }
        ], this.traffics.daily_users.date, '날짜(월-일)', color2);
        await this.render(this.element3, [
            { name: '트래픽', data: this.traffics.monthly_traffic.count }
        ], this.traffics.monthly_traffic.date, '날짜(월)', color1);
        await this.render(this.element4, [
            { name: '접속자', data: this.traffics.monthly_users.count }
        ], this.traffics.monthly_users.date, '날짜(월)', color2);

        await this.service.render();
    }

    public async render(element: any, series: any, category: any, title: any, color: any) {
        element.nativeElement.innerHTML = "";

        let options = {
            series: series,
            chart: {
                height: 200,
                type: 'area',
                sparkline: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
                animation: {
                    enabled: true,
                    dynamicAnimation: {
                        enabled: true
                    }
                }
            },
            grid: {
                padding: {
                    right: 0,
                    left: 0
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: 'gradient', // 단색으로 변경하려면 'solid'
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.2,
                    opacityTo: 0.1,
                    stops: [0, 90, 100],
                    colorStops: [
                        {
                            offset: 0,
                            color: color[0], // 그래프 상단 색상
                            opacity: 0.5
                        },
                        {
                            offset: 100,
                            color: color[1], // 그래프 하단 색상
                            opacity: 0.5
                        }
                    ]
                }
            },
            stroke: {
                curve: 'smooth',
                width: 1,
                colors: [color[0]] // 선의 색상
            },
            xaxis: {
                type: 'text',
                categories: category,
                labels: {
                    show: true, // x축 레이블 표시
                },
                title: {
                    text: title,
                },
            },
            yaxis: {
                min: 0,
                labels: {
                    show: true, // x축 레이블 표시
                },
                title: {
                    text: "수",
                },
            }
        };

        let chart = new ApexCharts(element.nativeElement, options);
        chart.render();
    }
}

export default PortalKreonetadmWidgetTrafficComponent;