import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.institutes.item');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';
import moment from 'moment';

@Component({
    selector: 'wiz-page-institutes-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/page.institutes.item/view.scss */
.btn {
  border-radius: 20px;
  height: 40px;
}

.btn-opt {
  padding: 12px 30px;
  border: 1px solid #72787F;
}
.btn-opt:hover {
  color: #5263FF;
  border: 1px solid #5263FF;
  background-color: #b5bcff;
}
.btn-opt.active {
  background-color: #5263FF;
  color: #FFF;
  border: 1px solid #5263FF;
}

.gray {
  background-color: #E9E8EE !important;
}

.dark-gray {
  background-color: #2b2c2e !important;
}
.dark-gray .border-line {
  color: #fff;
}

.light-gray {
  background-color: #F8F8F8 !important;
}

.black {
  background-color: black !important;
}
.black .border-line {
  color: #fff;
}

.sticky-menu {
  z-index: 9;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 1440px;
  width: 90vw;
  margin: 0px auto;
  border-top: 1px solid #2b2c2e;
  border-left: 1px solid #2b2c2e;
  border-right: 1px solid #2b2c2e;
}
@media (max-width: 768px) {
  .season-page {
    width: 92vw;
  }
}

.season-page::-webkit-scrollbar {
  display: none;
}

.apex-chart-rounded {
  min-height: 360px;
}

.content-institute {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/background/institute.jpg);
  background-size: cover;
}

.content-fee {
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url(/assets/background/fee.jpg);
  background-size: cover;
}
.content-fee .border-line {
  color: #fff;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  background: #fff;
  border-left: 1px solid var(--wc-border);
  z-index: 10;
}
.sidebar .card {
  border-radius: 0;
  border: none;
}
.sidebar .card > * {
  border-radius: 0;
}

.device-editor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.wiz-form {
  background-color: #fff;
}`],
})
export class PageInstitutesItemComponent implements OnInit {

    public sidebar: any = null;
    public network_name = '';
    public selected: any = {
        network: { tr: 30 },
        contact: null
    };
    public user = {};
    public userRole = '';
    public userName = '';
    public userExists = false;
    public netUserExists = false;
    public data: any = {
        info: {},
        contact: [],
        network: [],
        networkContact: [],
        traffic: null
    };

    public institute_ns: string = '';
    public loaded: boolean = false;

    public chartData: any = {
        annual_fee: {
            series: [],
            labels: []
        }
    }

    constructor(@Inject( Service)         public service: Service,@Inject( Location    )         public location: Location    ) {
        this.institute_ns = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
    }

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load(selected: number = 0) {
        const { code, data } = await wiz.call("load", { ns: this.institute_ns });
        if (code != 200) {
            this.location.back();
            return;
        }
        const { info, contact, network } = data;
        try {
            info.created = moment(info.created).format("YYYY-MM-DD");
        } catch (e) {
        }
        this.data.info = info;
        this.data.contact = contact;
        this.data.network = network;

        let values = {};
        for (let i = 0; i < this.data.network.length; i++) {
            let net = this.data.network[i];
            if (net.annual_fee && net.annual_fee * 1 > 0)
                values[net.name] = net.annual_fee * 1 / 10000;
        }

        this.chartData.annual_fee.labels = [];
        this.chartData.annual_fee.series = [];
        for (let key in values) {
            this.chartData.annual_fee.labels.push(key)
            this.chartData.annual_fee.series.push(values[key]);
        }

        if (this.data.network.length > 0) {
            if (selected == -1) selected = this.data.network.length - 1;
            await this.selectNetwork(this.data.network[selected]);
        } else {
            this.selected.network = null;
        }

        this.loaded = true;
        this.userRole = this.service.auth.session.role;
        this.userEmail = this.service.auth.session.email;
        this.userName = this.service.auth.session.name;
        this.userIns = this.service.auth.session.ins_role;
        this.user = this.service.auth.session;
        this.userExists = this.data.contact.some(user => user.name === this.userName);

        this.service.institute = this.data;
        await this.service.render();
    }

    public userIcon(item: any) {
        if (item.role == '관리책임자') return 'fa-user-gear';
        if (item.role == '전산담당자') return 'fa-user-shield';
        if (item.role == '연회비담당자') return 'fa-user-clock';
        return 'fa-user';
    }

    public async selectNetwork(item: any, tr: number = 30) {
        this.data.traffic = null;
        await this.service.render();

        const { code, data } = await wiz.call("loadNetwork", { ...item, tr: tr });
        if (code != 200) {
            await this.alert("오류가 발생했습니다.");
            return;
        }

        const { contact, traffic } = data;
        this.data.networkContact = contact;

        this.userName = this.service.auth.session.name;
        this.netUserExists = this.data.networkContact.some(user => user.name === this.userName);
        if (traffic) {
            for (let i = 0; i < traffic._time.length; i++)
                traffic._time[i] = (moment(traffic._time[i]).add(9, 'hours')).format("YYYY-MM-DD HH:mm:ss");

            this.data.traffic = {
                category: traffic._time,
                series: [{
                    name: 'IN',
                    data: traffic._in
                }, {
                    name: 'OUT',
                    data: traffic._out
                }]
            };
        }

        this.selected.network = item;
        this.network_name = this.selected.network.name;
        this.selected.network.tr = tr;
        await this.service.render();
    }

    public async createNetwork() {
        this.selected.network = {
            ins_id: this.data.info.id,
            status: 'active',
            paytype: '무료',
            network_type: '특별회원',
            network_level: '첨단연구회원',
            network_category: '',
            location: '대전',
            location_to: '서울',
            group: '대전'
        };
        await this.service.render();
    }

    public async updateNetwork() {
        if (!this.selected.network) return;
        let data = JSON.parse(JSON.stringify(this.selected.network));
        try { data.extra = JSON.stringify(data.extra); } catch (e) { }

        const { code } = await wiz.call("updateNetwork", data);

        if (code == 200) {
            await this.alert("저장되었습니다", 'success');
        } else {
            await this.alert("저장 중 오류가 발생했습니다.");
        }

        if (!this.selected.network.id) {
            await this.load(-1);
        }

        await this.service.render();
    }

    public async deleteNetwork() {
        let res = await this.alert("정말로 삭제하시겠습니까?", 'error', '삭제', '취소');
        if (!res) return;
        let data = this.selected.network;
        await wiz.call("deleteNetwork", data);
        await this.load();
    }

    public async sidebarClose() {
        this.sidebar = null;
        await this.service.render();
    }

    public async openInfo() {
        this.sidebar = 'info';
        await this.service.render();
    }

    public async updateInfo() {
        let data = this.data.info;
        const { code } = await wiz.call("updateInfo", data);
        if (code == 200) {
            await this.alert("저장되었습니다", 'success');
        } else {
            await this.alert("저장 중 오류가 발생했습니다.");
        }
        await this.service.render();
    }

    public async createContact(obj_type: any = 'ins') {
        this.sidebar = 'contact';

        let obj_id = this.data.info.id;
        if (obj_type == 'net') {
            obj_id = this.selected.network.id;
        }

        this.selected.contact = {
            obj_type: obj_type,
            obj_id: obj_id,
            role: '관리책임자'
        };
        await this.service.render();
    }

    public async openContact(item: any) {
        this.sidebar = 'contact';
        this.selected.contact = item;
        await this.service.render();
    }

    public async updateContact() {
        let data = this.selected.contact;
        const { code } = await wiz.call("updateContact", data);
        if (code == 200) {
            await this.alert("저장되었습니다", 'success');
            await this.sidebarClose();
        } else {
            await this.alert("저장 중 오류가 발생했습니다.");
        }
        await this.load();
    }

    public async deleteContact() {
        let res = await this.alert("정말로 삭제하시겠습니까?", 'error', '삭제', '취소');
        if (!res) return;
        let data = this.selected.contact;
        await wiz.call("deleteContact", data);
        await this.sidebarClose();
        await this.load();
    }

    // 장비 설정 관련 이벤트
    public deviceSelectedItem: any = null;
    public async deviceSelect() {
        this.deviceSelectedItem = this.selected.network;
        if (!this.deviceSelectedItem.extra.devices) this.deviceSelectedItem.extra.devices = [];
        await this.service.render();
    }

    public async deviceSelected(data: any) {
        this.deviceSelectedItem.extra.devices = data;
        let item = JSON.parse(JSON.stringify(this.deviceSelectedItem));
        await wiz.call("updateNetwork", { id: item.id, extra: JSON.stringify(item.extra) });
        this.deviceSelectedItem = null;
        await this.service.render();
    }

    public async deviceCanceled() {
        this.deviceSelectedItem = null;
        await this.service.render();
    }

}

export default PageInstitutesItemComponent;