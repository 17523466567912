import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.page.admin.menual');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-page-admin-menual',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/page.page.admin.menual/view.scss */
/* src/app/page.page.admin.menual/view.scss: no such file or directory */`],
})
export class PagePageAdminMenualComponent implements OnInit {
    @Input() title: any;

    constructor(@Inject( Service) public service: Service) {}
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.service.render();
    }
}

export default PagePageAdminMenualComponent;