import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.report');
import { OnInit, Input, ElementRef } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ApexCharts from 'apexcharts';
import moment from 'moment';

@Component({
    selector: 'wiz-page-report',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/page.report/view.scss */
.container-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 230px;
  border: 1px solid #DBE0E5;
}

.title {
  text-align: center;
  color: #5263FF;
}`],
})
export class PageReportComponent implements OnInit {
    @Input() title: any;
    // public element: ElementRef;
    public selected: any = {
        network: null,
        contact: null
    };

    public data: any = {
        info: {},
        contact: [],
        network: [],
        networkContact: [],
        traffic: null
    };

    public period = [7, 14, 30, 180];

    public loaded: boolean = false;
    public animation: boolean = true;

    public chartData: any = {
        annual_fee: {
            series: [],
            labels: []
        }
    }

    public week: boolean = true;
    public twoWeek: boolean = true;
    public month: boolean = true;
    public sixMonth: boolean = true;
    public series: any = [];
    public category: any = ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"];
    public date: any;

    constructor(@Inject( Service)         public service: Service,@Inject( ElementRef    )  public element: ElementRef    ) {
        this.institute_ns = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        this.date = moment().format('YYYY-MM-DD');
        await this.load();
        for (let one of this.period) {
            await this.selectNetwork(this.selected.network, one);
            await this.render(one);
        }
        await this.service.render(1000);
        print('')
    }

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load(reload: boolean = false, selected: number = 0) {
        let data: any = await this.loader.load(reload);
        this.data = data;
        this.chartData = data.chart;

        if (this.data.network.length > 0) {
            if (selected == -1) selected = this.data.network.length - 1;
            await this.selectNetwork(this.data.network[selected]);
        } else {
            this.selected.network = null;
        }

        this.loaded = true;
        this.service.institute = this.data;
        await this.service.render();
    }

    public loader: any = {
        loaded: false,
        data: {},
        load: async (reload: boolean = false) => {
            if (!reload && this.loader.loaded)
                return this.loader.data;
            this.loader.loaded = false;
            await this.service.render();
            const { code, data } = await wiz.call("load", { ns: this.institute_ns });
            if (code != 200) {
                await this.service.href("/admin/info/institutes");
                return;
            }
            try {
                data.info.created = moment(data.info.created).format("YYYY-MM-DD");
            } catch (e) {
            }

            let values = {};
            for (let i = 0; i < data.network.length; i++) {
                let net = data.network[i];
                if (net.annual_fee && net.annual_fee * 1 > 0)
                    values[net.name] = net.annual_fee * 1 / 10000;
            }

            let chartData: any = { annual_fee: {} };
            chartData.annual_fee.labels = [];
            chartData.annual_fee.series = [];
            for (let key in values) {
                chartData.annual_fee.labels.push(key)
                chartData.annual_fee.series.push(values[key]);
            }
            data.chart = chartData;
            for (let key in data)
                this.loader.data[key] = data[key];
            this.loader.loaded = true;
            await this.service.render();
            return data;
        }
    };

    public async selectNetwork(item: any, tr: number = 30) {

        this.data.traffic = null;
        await this.service.render();

        const { code, data } = await wiz.call("loadNetwork", { ...item, tr: tr });
        if (code != 200) {
            await this.alert("오류가 발생했습니다.");
            return;
        }

        const { contact, traffic } = data;

        this.data.networkContact = contact;

        if (traffic) {
            for (let i = 0; i < traffic._time.length; i++)
                traffic._time[i] = (moment(traffic._time[i]).add(9, 'hours')).format("YYYY-MM-DD HH:mm:ss");

            this.data.traffic = {
                category: traffic._time,
                series: [{
                    name: 'IN',
                    data: traffic._in
                }, {
                    name: 'OUT',
                    data: traffic._out
                }]
            };
            this.category = traffic._time;
            this.series = [{
                name: 'IN',
                data: traffic._in
            }, {
                name: 'OUT',
                data: traffic._out
            }];

        }
        else {
            if (tr == 7) this.week = false;
            else if (tr == 14) this.twoWeek = false;
            else if (tr == 30) this.month = false;
            else if (tr == 180) this.sixMonth = false;
        }

        this.selected.network = item;
        this.selected.network.tr = tr;
        await this.service.render();
    }

    public async render(period) {
        let text = ""
        if (period == 7) text = "<1주>";
        else if (period == 14) text = "<2주>";
        else if (period == 30) text = "<1개월>";
        else if (period == 180) text = "<6개월>";
        let options = {
            series: this.series,
            // series: this.data.traffic.series,
            chart: {
                height: 220,
                width: 550,
                type: 'area',
                sparkline: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
                animation: {
                    enabled: this.animation,
                    dynamicAnimation: {
                        enabled: this.animation
                    }
                }
            },
            grid: {
                padding: {
                    right: 0,
                    left: 0
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            xaxis: {
                type: 'datetime',
                categories: this.category,
                labels: {
                    show: true,
                },
                title: {
                    text: "날짜(시간)",
                },
                tooltip: {
                    enabled: false // X축의 날짜 툴팁 비활성화
                }
            },
            yaxis: {
                labels: {
                    show: true
                },
                title: {
                    text: "Mbps",
                },
            },
            tooltip: {
                enabled: this.animation,
                x: {
                    format: 'yyyy/MM/dd HH:mm'
                },
                y: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        return value + " Mbps"
                    }
                }
            },
            title: {
                text: text,
                align: 'left',
                offsetX: 24,
                offsetY: 24,
                style: {
                    fontSize: '18px',
                    cssClass: 'apexcharts-yaxis-title'
                }
            }
        };

        let chart = new ApexCharts(document.getElementById(`chart-${period}`), options);
        if (this.series.length > 0) chart.render();
        await this.service.render();
    }
}

export default PageReportComponent;