import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonet.widget.node');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-portal-kreonet-widget-node',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.kreonet.widget.node/view.scss */
/* src/app/portal.kreonet.widget.node/view.scss: no such file or directory */`],
})
export class PortalKreonetWidgetNodeComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public node = [];

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/authenticate");
        await this.load()
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: location.id });
        this.node = data.node;
        await this.service.render();
    }


}

export default PortalKreonetWidgetNodeComponent;