import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.users');
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-kreonetadm-users',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.kreonetadm.users/view.scss */
.container {
  height: max-content;
  width: 1800px;
}

.table-vcenter {
  width: 100%;
  margin: 0 auto;
}

.card {
  width: 90%;
  margin: 0 auto;
  max-height: 90%;
}

.buttons {
  width: 90%;
  margin: 0 auto;
  background: none;
  border: none;
  text-align: right;
}

.search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 40%;
}
@media (max-width: 1200px) {
  .search-box {
    width: 97%;
  }
}

.dropdown1 .dropdown-toggle {
  padding-left: 0.5rem;
  border-radius: 7px;
  justify-content: space-between;
  height: 36.5px;
  width: 90px;
  color: #707070;
  border: 1px solid #adacac;
  font: normal normal bold 14px/16px SUIT;
}
.dropdown1 .dropdown-menu {
  max-width: 91px;
  min-width: 5%;
  transform: translate3d(455.5px, 202px, 0px);
}
.dropdown1 .dropdown-item {
  min-width: 0;
}

.card-table .table {
  min-width: 1520px;
}
.card-table .table th {
  font-size: 14px;
  background: #EFF4FE;
  padding: 8px 12px;
}
.card-table .table thead {
  position: sticky;
  top: 0;
}
.card-table .table tbody {
  background: #fff;
}
.card-table .table tbody tr {
  cursor: pointer;
}
.card-table .table tbody tr:hover {
  background: var(--wc-background);
}
.card-table .table tbody td {
  white-space: nowrap;
}
.card-table .table tbody .user-status-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  line-height: 1;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 10px;
  background: var(--wc-blue);
  color: #fff;
}
.card-table .table tbody .btn i {
  width: 16px;
}

.form-control {
  padding: 0.5625rem 0.75rem !important;
  font-size: 0.875rem !important;
}`],
})
export class PortalKreonetadmUsersComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
    }
    public mcate = "name";
    public institutes = [];
    public user_roles = [
        '-',
        '관리책임자',
        '전산담당자',
        '연회비담당자',
        '연구책임자',
        '실무연구자'
    ];
    @ViewChild('handsontable')
    public table: ElementRef;
    public handsontable: any = null;
    public year: number = 0;
    public listloaded: boolean = false;
    public list: any = [];
    public status: string = 'active';
    public post = {};
    public search_word = '';
    public searchForm: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        page: 1,
        end: 1,
        start: 1,
    };

    public async alert(message: string, status: any = "error", confirm: boolean = false) {
        const result = await this.service.alert.show({
            title: '',
            message: message,
            cancel: confirm ? "취소" : undefined,
            actionBtn: status,
            action: "확인",
            status: status
        });
        return confirm ? result : true;
    }

    private async search() {
        if (this.search_word.length === 0) {
            await this.alert("검색어를 입력해주세요.");

        }
        this.pagenation.page = 1;
        await this.load();
    }
    public async load() {
        this.listloaded = false;
        let { code, data } = await wiz.call("list", { page: this.pagenation.page, text: this.searchForm.text, status: this.status, mcate: this.mcate });
        if (code != 200) return;
        let { rows, lastpage, institutes_list } = data;
        this.list = rows;
        this.institutes = institutes_list;
        this.pagenation.start = Math.floor((this.pagenation.page - 1) / 10) * 10 + 1;
        this.pagenation.end = data.lastpage;
        this.listloaded = true;
        await this.service.render();
    }

    // public async pageMove(page: number) {
    //     await this.load(page);
    // }

    public async update() {
        let isDuplicate = this.list.map(user => user.email)
            .filter((email, index, arr) => arr.indexOf(email) !== index).length > 0;

        if (isDuplicate) {
            await this.alert('중복된 이메일이 존재합니다.', 'warning');
            return;
        }
        const { code, data } = await wiz.call("update", { data: JSON.stringify(this.list) })
        if (code == 200) {
            await this.alert('저장되었습니다.', 'success')
        } else {
            await this.alert('저장에 실패했습니다.', 'error')
        }
    }

    public async changeStatus(item: any, status: string) {
        let res = false;
        if (status == 'active')
            res = await this.alert('사용자 접속을 승인하겠습니까?', 'success');
        if (status == 'inactive')
            res = await this.alert('사용자를 차단하겠습니까?', 'error');
        if (!res) return;
        item.status = status;
        await wiz.call("changeStatus", item);
        await this.pageLoad(this.searchForm.page);
    }
    public async eachUpdate(id, user) {
        let isDuplicate = this.list.some(existingUser =>
            existingUser.email == user.email && existingUser.id != id
        );

        if (isDuplicate) {
            await this.alert('중복된 이메일이 존재합니다.', 'warning');
            return;
        }
        this.post.id = id;
        this.post = user;
        const { code, data } = await wiz.call("eachUpdate", this.post)
        if (code == 200) {
            await this.alert('저장되었습니다.', 'success')
        } else {
            await this.alert('저장에 실패했습니다.', 'error')
        }
        this.post = [];
    }
    public async changeRole(item: any) {
        let res = false;
        if (item.role == 'admin') {
            res = await this.alert('관리자 권한을 해제하시겠습니까??', 'error');
        } else {
            res = await this.alert('관리자로 지정하시겠습니까?', 'success');
        }
        if (!res) return;
        item.role = item.role == 'admin' ? 'user' : 'admin';
        item.membership = item.membership == 'admin' ? 'user' : 'admin'
        await wiz.call("changeRole", item);
        await this.pageLoad(this.searchForm.page);
    }

    public async deleteUser(user: string) {
        const confirmed = await this.alert('사용자를 삭제하시겠습니까?', 'success', true);
        if (!confirmed) return;

        const { code } = await wiz.call("delete", { user })
        if (code == 200) {
            await this.alert('삭제되었습니다.', 'success')
        } else {
            await this.alert('삭제에 실패했습니다.', 'error')
        }
        this.pageLoad(1);
    }
    public async selectValue(category: string): void {
        this.mcate = category;
        this.searchForm.text = '';
        this.searchForm.page = 1;
        await this.load()
    }
    private pageLoad(p: number, status: any = null) {
        this.pagenation.page = p;
        if (status) this.status = status;
        this.load();
    }
}

export default PortalKreonetadmUsersComponent;