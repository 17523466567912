import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.admin.menual');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router } from '@angular/router';
import moment from "moment";

@Component({
    selector: 'wiz-portal-kreonetadm-admin-menual',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.kreonetadm.admin.menual/view.scss */
.card-header h2 {
  font: normal normal bold 24px/27px NanumSquareOTF;
}

.container {
  max-width: 1570px;
  width: 95%;
  height: 85%;
  background: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1019607843);
  border-radius: 10px;
  opacity: 0.9;
}

.page-title {
  font: normal normal bold 24px/27px NanumSquareOTF;
  height: 39px;
}

span {
  font: normal normal normal 16px SUIT;
}

.vertical {
  border: 0.5px solid #808080;
  height: 241px;
  margin-top: auto;
  margin-bottom: auto;
}

li {
  background: #ECF5FF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  width: 511px;
  height: 32px;
  display: flex;
  align-items: center;
}
li a {
  padding-left: 0.5rem;
  color: #5263FF;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  font: normal normal normal 16px/20px SUIT;
  text-decoration: underline;
}
li .btn-remove-file {
  height: 30px !important;
  width: 30px !important;
  border: none;
  background-color: #ECF5FF;
  margin-right: 5px;
}

.row-user {
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
  margin-top: 1rem;
  height: 50px;
  display: flex;
  width: 100%;
  max-width: 1570px;
  flex-direction: row;
  justify-content: space-between;
  font: normal normal normal 16px/18px SUIT;
}
.row-user .btn-delete {
  width: 83px;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background: none;
  border: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  box-shadow: none;
}
.row-user .btn-delete:hover {
  background: none;
}

.btn-secondary {
  width: 132px;
  height: 44px;
  border-radius: 10px;
}

.btn-outline-blue {
  border-radius: 10px;
}

.text-muted {
  text-align: left;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #5D5D5D;
  height: 28px;
}

.workspace-list-header {
  max-width: 1570px;
  width: 95%;
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  margin: 0 auto;
}

.page-header {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  text-align: left;
  height: 39px;
  justify-content: left;
}
.page-header .buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  height: 39px;
  gap: 8px;
}

@media (max-width: 1024px) {
  .text-muted {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .workspace-list-header {
    height: auto;
  }
  .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 8px;
  }
  button.btn {
    width: auto !important;
    height: auto !important;
  }
}
@media (max-width: 768px) {
  .workspace-list-header {
    height: auto;
  }
  .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .text-muted {
    display: none;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 8px;
  }
  button.btn {
    width: auto !important;
    height: auto !important;
  }
}
@media (max-width: 480px) {
  .workspace-list-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
  }
  .page-header {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    height: auto;
  }
  .workspace-list-header::after {
    content: "";
    display: block;
    height: 64px;
  }
  h2.page-title {
    font-size: 18px;
  }
  .text-muted {
    display: none;
  }
}
.form-group {
  padding: 15px 0px;
  border-top: 1px solid #E3E3E3;
}
.form-group .form-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px SUIT;
  margin-bottom: 0px;
}
.form-group .form-selectgroup-label {
  padding: 7px 16px;
}

.badge {
  width: 84px;
  height: 34px;
  font: normal normal bold 18px/21px NanumSquareOTF;
  text-align: center;
}

.col-auto {
  display: flex;
  flex-direction: row;
}

.dropdown a {
  width: 132px;
  height: 44px;
  border: 1px solid #5263FF;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/18px NanumSquareOTF;
  margin-left: 1230px;
}

.btn-remove-file {
  height: 30px !important;
  width: 70px !important;
  margin-left: auto;
  border: none;
  background-color: #F6F9FE;
  margin-right: 5px;
}

.btn-outline-blue {
  width: 132px;
  height: 44px;
}

.btn-remove {
  width: 132px;
  height: 44px;
  background: #FF7676;
  color: white;
  border: none;
  border-radius: 10px;
}

.btn-md {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  border-radius: 10px;
  margin-left: auto;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  color: #6D6D6D;
}
.btn-md img {
  filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(70deg) brightness(94%) contrast(88%);
}

.btn-save {
  width: 132px;
  height: 44px;
  border-radius: 10px;
  background: #5263ff;
  color: white;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: white;
  background: #5263FF;
  border-color: #5263FF;
}

.form-selectgroup-label {
  height: 44px;
  background: #F2F2F2;
  border-radius: 10px;
  color: #6D6D6D;
  display: flex !important;
  align-items: center;
  border: none;
}

.from-selectgroup-item {
  margin-right: 10px !important;
}

.edit-form {
  padding: 24px 32px;
}

.editor-container {
  width: 1280px;
}

.btn-season {
  background-color: #FE5A33;
  border: 1px solid #FE5A33;
  color: white;
}
.btn-season:hover {
  background-color: #fff;
  border: 1px solid #FE5A33;
  color: #FE5A33;
}

.page-icon.back-btn {
  cursor: pointer;
  background-color: #FE5A33 !important;
}

input,
textarea {
  text-align: left !important;
  border-radius: 0 !important;
}

.card {
  max-width: 1570px;
  width: 95%;
  height: 93%;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  border: none;
  overflow: auto;
}

.content {
  padding: 25px;
  width: 100%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}
.content h2 {
  margin: 0 auto;
  width: 1440px;
  text-align: center;
  font: normal normal bold 36px/41px NanumSquareOTF;
}
.content h4 {
  margin: 10px auto 10px;
  width: 1440px;
  color: #FF7676;
  text-align: center;
}
.content h3 {
  width: 1440px;
  text-align: left;
  border-bottom: solid 1px;
  font: normal normal bold 24px/27px NanumSquareOTF;
}
.content button {
  width: 336px;
  height: 76px;
  font: normal normal normal 24px/27px NanumSquareOTF;
  border: none;
  border-radius: 10px;
}
.content .content-body {
  min-height: 850px;
  background-color: white;
  text-align: left;
}

.table-last {
  width: 1440px;
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last tbody tr {
  width: 1440px;
  height: 80px;
  border-bottom: solid 0.5px #D1D1D1;
}
.table-last tbody tr td {
  height: 60px;
  font: normal normal normal 18px/21px NanumSquareOTF;
}

li {
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 1436px;
  height: 60px;
  display: flex;
  align-items: center;
  text-decoration: underline;
}

img {
  color: #5263FF;
}

.btn-blue {
  border-radius: 10px;
  width: 94px;
  height: 40px;
  font: normal normal normal 16px/21px SUIT;
}

.file-box {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  font: normal normal medium 18px/23px SUIT;
  color: #000000;
}
.file-box .btn-blue {
  border-radius: 10px;
  width: 94px;
  height: 40px;
  font: normal normal normal 16px/21px SUIT;
}

.file-card {
  display: flex;
  width: 100%;
  height: 302px;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
}

ngx-dropzone {
  width: 500px;
  height: 241px;
  background: #EEF7FF;
  border: 1px dashed #2547F4;
  border-radius: 7px;
}
ngx-dropzone ngx-dropzone-label {
  font: normal normal medium 18px/23px SUIT !important;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 1920px;
  margin: 0px auto;
  border-radius: none;
  vertical-align: middle;
  text-align: left;
}
.season-page .header {
  border-radius: none;
}

.sticky-top {
  padding: 8px 48px;
  position: sticky;
}
.sticky-top .text-align-center {
  justify-content: center;
  align-items: center;
}

.btn-news {
  padding: 12px 30px;
  background: #5263FF 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: white;
  margin-left: 10px;
}

.btn-gray {
  padding: 12px 30px;
  margin-left: 10px;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  background-color: #D1D1D1;
  border-radius: 10px;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.season-page::-webkit-scrollbar {
  display: none;
}

.content-container {
  padding: 64px auto;
  font-size: 18px;
}
.content-container .content-body {
  max-width: 860px;
  margin: 0 auto;
}

label {
  margin-left: auto;
}

.filebox {
  display: flex;
  justify-content: flex-end;
}

.btn-file {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  color: #6D6D6D;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-file img {
  filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(70deg) brightness(94%) contrast(88%);
}
.btn-file:hover {
  background: #5263FF;
  color: #fff;
}

#file {
  display: none;
}

li {
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
li a {
  text-decoration: underline;
}
li img {
  filter: invert(17%) sepia(41%) saturate(7462%) hue-rotate(199deg) brightness(89%) contrast(102%);
  width: 20px;
  height: 20px;
}

.card {
  max-width: 1570px;
  width: 95%;
  height: 93%;
}`],
})
export class PortalKreonetadmAdminMenualComponent implements OnInit {
    public search: any = {
        page: 1,
        text: ''
    };
    public PDF: any = "";
    public pagenation: any = {
        end: -1,
        start: -1
    };
    public item: any = {
        files: []
    };
    public fd = new FormData();
    public selectedValue: string | null = null;
    public files = [];
    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    @Input() post_id = '';

    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
        await this.load();
    }

    public async load(page = 1) {
        if (this.post_id != 'new') {
            const { code, data } = await wiz.call("get")
            this.item.files = data;
            // console.log(this.item.date, '아이템 데이트')
            // this.item.files = JSON.parse(data.replace(/'/g, '"'));
        }
        await this.preview()
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public selectValue(status: string, role: string, index: number): void {
        this.list[index].status = status;
        this.list[index].role = role;
    }
    public async update() {
        // if (this.item.title.length == 0) {
        //     await this.alert('제목을 입력해주세요');
        //     return
        // }

        // const [year, month] = this.item.date.split('-');
        // this.item.date = `${year}-${month}-01`;

        // console.log(this.item, '디스 아이템')

        // this.fd.append('data', JSON.stringify(this.item))
        this.fd.append('data', JSON.stringify(this.item.files))

        let url = wiz.url('update')

        const { code, data } = await this.service.file.upload(url, this.fd);
        if (code == 200) {
            await this.alert('저장되었습니다', '', 'success', '확인');
        }


        this.router.navigateByUrl('/admin/report')
    }
    public onDropdownClick(event: MouseEvent, id: string) {
        event.stopPropagation();
        this.download(id);
    }
    public async download() {
        // 아이템을 list에서 찾음
        let files = this.item.files;
        console.log(files, '파일스')
        // 각 파일에 대해 다운로드 URL 생성 및 새 탭에서 다운로드 시작
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            console.log(file, '파일')
            // const url = `/file/download/enter/${this.item.id}/${file}/${file}?attatch=false`;
            let url = wiz.url('download?id=' + this.item.id + "&title=" + files)
            // <a> 태그를 생성하여 다운로드
            const link = document.createElement('a');
            link.href = url;
            link.download = file;  // 파일 이름 지정 (선택사항)

            // 링크를 문서에 추가하고 클릭하여 다운로드 시작
            document.body.appendChild(link);
            link.click();

            // 링크를 문서에서 제거
            document.body.removeChild(link);
        }
    }
    // public async download(item, type) {
    //     if (!item[type] && !item[type + "x"]) return;
    //     if (await this.service.auth.allow(false)) {
    //         await this.alert("로그인 후 이용해주세요.");
    //         return 0;
    //     }
    //     type = item[type] ? type : type + "x";
    //     let download = wiz.url('download?id=' + item.id + "&title=" + item.title + "&type=" + type)
    //     window.open(download, '_blank');
    // }

    public async fileUpload(e) {
        console.log(e.addedFiles, '이벤트')
        for (let i = 0; i < e.addedFiles.length; i++) {
            let file = e.addedFiles[i]
            this.filename = file.name;
            if (!file.filepath) file.filepath = file.name;
            this.fd.append('file[]', file);
            this.item.files.push(file.filepath);
            console.log(this.item.files, '아이템 파일스')
        }
        await this.service.render();
    }
    public async removeFile(i) {
        this.item.files.splice(i, 1)
    }

    // public async preview(item) {
    //     console.log(item, '아이템')
    //     if (!item.endsWith('.pdf')) return;
    //     let download = wiz.url('pdf?id=' + this.item.id + "&title=" + item)
    //     console.log(download)
    //     let res = await fetch(download, {
    //         method: "post",
    //     });

    //     const blob = await res.blob();
    //     const a = document.createElement('a');
    //     a.style.display = 'none';
    //     const download_url = window.URL.createObjectURL(blob);
    //     a.href = download_url;
    //     a.target = "_blank";

    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(download_url);
    // }

    public async preview() {
        if (!item.endsWith('.pdf')) return;
        let download = wiz.url('pdf?id=' + this.item.id + "&title=" + item)
        this.PDF = this.sanitizer.bypassSecurityTrustResourceUrl(download);
        await this.service.render();
    }

    public onRemove(event) {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);
    }
    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("delete", { id: this.item.id });
        this.router.navigateByUrl('/admin/report')
    }
}

export default PortalKreonetadmAdminMenualComponent;