import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonet.chart.traffic');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ApexCharts from 'apexcharts';

@Component({
    selector: 'wiz-portal-kreonet-chart-traffic',
template: templateSource || '',
    styles: [``],
})
export class PortalKreonetChartTrafficComponent implements OnInit {

    @Input() title: string = '';
    @Input() series: any = [];
    @Input() category: any = ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"];
    @Input() height: number = 180;
    @Input() animation: boolean = true;

    @ViewChild('chart')
    public element: ElementRef;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.render();
    }

    public async render() {
        let options = {
            series: this.series,
            chart: {
                // height: this.height,
                width: "97%",
                type: 'area',
                sparkline: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
                animation: {
                    enabled: this.animation,
                    dynamicAnimation: {
                        enabled: this.animation
                    }
                }
            },
            grid: {
                padding: {
                    right: 0,
                    left: 0
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            xaxis: {
                type: 'datetime',
                categories: this.category,
                labels: {
                    show: true,
                },
                title: {
                    text: "날짜(시간)",
                },
                tooltip: {
                    enabled: false // X축의 날짜 툴팁 비활성화
                }
            },
            yaxis: {
                labels: {
                    show: true

                },
                title: {
                    text: "Kbps",
                },
            },
            tooltip: {
                enabled: this.animation,
                x: {
                    format: 'yyyy/MM/dd HH:mm'
                },
                y: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        return value + " Kbps"
                    }
                }
            }
        };

        if (this.title) {
            options.title = {
                text: this.title,
                offsetX: 24,
                style: {
                    fontSize: '18px',
                    cssClass: 'apexcharts-yaxis-title'
                }
            };
        }

        let chart = new ApexCharts(this.element.nativeElement, options);
        chart.render();
    }
}

export default PortalKreonetChartTrafficComponent;