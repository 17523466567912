import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.apply.admin.notice.item');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";

@Component({
    selector: 'wiz-portal-apply-admin-notice-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.apply.admin.notice.item/view.scss */
@charset "UTF-8";
.card-header h2 {
  font: normal normal bold 24px/27px NanumSquareOTF;
}

.header-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}

.btn-outline-blue {
  border-radius: 10px;
}

.page-title {
  font: normal normal bold 24px/27px NanumSquareOTF;
  height: 39px;
}

span {
  font: normal normal normal 16px SUIT;
}

.btn-secondary {
  width: 132px;
  height: 44px;
  border-radius: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  height: 39px;
}

.text-muted {
  text-align: left;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #5D5D5D;
}

.workspace-list-header {
  max-width: 1570px;
  width: 95%;
  height: 80px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  margin: 0 auto;
}

.page-header {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  text-align: left;
  height: 39px;
}

@media (min-width: 768px) and (max-width: 1250px) {
  .workspace-list-header {
    height: auto;
  }
  .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .text-muted {
    display: none;
  }
}
@media (max-width: 768px) {
  .workspace-list-header {
    height: auto;
  }
  .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .text-muted {
    display: none;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 8px;
  }
  button.btn {
    width: auto !important;
    height: auto !important;
  }
}
@media (max-width: 480px) {
  .workspace-list-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
  }
  .page-header {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    height: auto;
  }
  .workspace-list-header::after {
    content: "";
    /* 빈 컨텐츠 */
    display: block;
    height: 64px;
    /* 버튼 높이 (44px) + 마진 (20px) */
  }
  h2.page-title {
    font-size: 18px;
  }
  .text-muted {
    display: none;
  }
}
.form-group {
  padding: 15px 0px;
  border-top: 1px solid #E3E3E3;
}
.form-group .form-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px SUIT;
  margin-bottom: 0px;
}
.form-group .form-selectgroup-label {
  padding: 7px 16px;
}

.badge {
  width: 84px;
  height: 34px;
  font: normal normal bold 18px/21px NanumSquareOTF;
  text-align: center;
}

.col-auto {
  display: flex;
  flex-direction: row;
}

.dropdown a {
  width: 132px;
  height: 44px;
  border: 1px solid #5263FF;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/18px NanumSquareOTF;
  margin-left: 1230px;
}

.btn-blue {
  width: 132px;
  height: 44px;
  border-radius: 10px;
  margin-left: auto;
}

.btn-md {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  border-radius: 10px;
  margin-left: auto;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  color: #6D6D6D;
}
.btn-md img {
  filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(70deg) brightness(94%) contrast(88%);
}
.btn-md:hover {
  background: #5263FF;
  color: #fff;
}

.btn-remove-file {
  height: 30px !important;
  width: 70px !important;
  margin-left: auto;
  border: none;
  background-color: #F6F9FE;
  margin-right: 5px;
}

.btn-remove {
  width: 132px;
  height: 44px;
  background: #FF7676;
  color: white;
  border: none;
  border-radius: 10px;
}

.btn-save {
  width: 132px;
  height: 44px;
  border: none;
  border-radius: 10px;
  background: #5263ff;
  color: white;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: white;
  background: #5263FF;
  border-color: #5263FF;
}

.form-selectgroup-label {
  height: 44px;
  background: #F2F2F2;
  border-radius: 10px;
  color: #6D6D6D;
  display: flex !important;
  align-items: center;
  border: none;
}

.from-selectgroup-item {
  margin-right: 10px !important;
}

.edit-form {
  padding: 24px 32px;
}

.btn-season {
  background-color: #FE5A33;
  border: 1px solid #FE5A33;
  color: white;
}
.btn-season:hover {
  background-color: #fff;
  border: 1px solid #FE5A33;
  color: #FE5A33;
}

.page-icon.back-btn {
  cursor: pointer;
  background-color: #FE5A33 !important;
}

input,
textarea {
  text-align: left !important;
  border-radius: 0 !important;
}

.card {
  max-width: 1570px;
  width: 95%;
  height: 93%;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  border: none;
  overflow: auto;
}

.content {
  padding: 25px;
  width: 100%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}
.content h2 {
  margin: 0 auto;
  width: 1440px;
  text-align: center;
  font: normal normal bold 36px/41px NanumSquareOTF;
}
.content h4 {
  margin: 10px auto 10px;
  width: 1440px;
  color: #FF7676;
  text-align: center;
}
.content h3 {
  width: 1440px;
  text-align: left;
  border-bottom: solid 1px;
  font: normal normal bold 24px/27px NanumSquareOTF;
}
.content button {
  width: 336px;
  height: 76px;
  font: normal normal normal 24px/27px NanumSquareOTF;
  border: none;
  border-radius: 10px;
}
.content .content-body {
  min-height: 850px;
  background-color: white;
  text-align: left;
}

.table-last {
  width: 1440px;
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last tbody tr {
  width: 1440px;
  height: 80px;
  border-bottom: solid 0.5px #D1D1D1;
}
.table-last tbody tr td {
  height: 60px;
  font: normal normal normal 18px/21px NanumSquareOTF;
}

label {
  margin-left: auto;
}

.filebox {
  display: flex;
  justify-content: flex-end;
}

.btn-file {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  color: #6D6D6D;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-file img {
  filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(70deg) brightness(94%) contrast(88%);
}
.btn-file:hover {
  background: #5263FF;
  color: #fff;
}

#file {
  display: none;
}

li {
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
li a {
  text-decoration: underline;
}
li img {
  filter: invert(17%) sepia(41%) saturate(7462%) hue-rotate(199deg) brightness(89%) contrast(102%);
  width: 20px;
  height: 20px;
}

.editor-container {
  width: 1280px;
}

.opt-area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.opt-area .btn {
  margin: 0 4px;
  border-radius: 10px;
  width: 94px;
  height: 44px;
  background-color: #F2F2F2;
  font: normal normal normal 16px Montserrat;
  border: none;
}
.opt-area .btn-opt {
  padding: 12px 30px;
  border: none;
}
.opt-area .btn-opt:hover {
  color: #5263FF;
  border: 1px solid #5263FF;
  background-color: #cfd4fb;
}
.opt-area .btn-opt.active {
  background-color: #5263FF;
  color: #FFF;
  border: 1px solid #5263FF;
}

.wiz-form {
  margin: 0 auto;
  width: 100%;
  background-color: white;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.wiz-form .wiz-form-rows {
  min-height: 80px;
}
.wiz-form .wiz-form-label {
  font: normal normal bold 18px/21px NanumSquareOTF;
}

.form-control {
  border-radius: 10px;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 1920px;
  margin: 0px auto;
  border-radius: none;
  vertical-align: middle;
  text-align: left;
}
.season-page .header {
  border-radius: none;
}

.sticky-top {
  padding: 8px 48px;
  position: sticky;
}
.sticky-top .text-align-center {
  justify-content: center;
  align-items: center;
}

.btn-news {
  padding: 12px 30px;
  background: #5263FF 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: white;
  margin-left: 10px;
}

.btn-gray {
  padding: 12px 30px;
  margin-left: 10px;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  background-color: #D1D1D1;
  border-radius: 10px;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.season-page::-webkit-scrollbar {
  display: none;
}

.content-container {
  padding: 64px auto;
  font-size: 18px;
}
.content-container .content-body {
  max-width: 860px;
  margin: 0 auto;
}`],
})
export class PortalApplyAdminNoticeItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public status: string = 'draft';
    @ViewChild('editor')
    public editorElement: ElementRef;
    public fd = new FormData();

    @Input() post_id: any = "new";
    public category: any = 'notice';
    public item: any = {
        user_id: '',
        title: '',
        content: '',
        status: '',
        category: 'new',
        featured_image: '',
        planstart: '',
        planend: '',
        files: []
    };
    public editor: any;
    public contentEditor: any;
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.init();
    }

    public async init() {
        if (this.post_id == 'new') {
            this.item = {
                category: 'noti',
                status: 'draft',
                title: '',
                content: '',
                featured_image: '',
                planstart: moment().format("YYYY-MM-DD"),
                planend: moment().format("YYYY-MM-DD"),
                created: moment().format("YYYY-MM-DD"),
                files: []
            };
        } else {
            const { code, data } = await wiz.call("get", { id: this.post_id });
            this.item = data;
            this.item.files = JSON.parse(data.files.replace(/'/g, '"'));
            this.item.planstart = moment(this.item.planstart).format("YYYY-MM-DD");
            this.item.planend = moment(this.item.planend).format("YYYY-MM-DD");
        }

        const CONTENT_ID = 'textarea#content';
        const Content = this.item.content || '';
        this.contentEditor = await this.createEditor(CONTENT_ID, Content);

        await this.service.render();
    }

    public async createEditor(selector: string, content: string) {
        const opt = {
            toolbar: {
                items: 'heading | bold italic strikethrough underline | fontColor highlight fontBackgroundColor | bulletedList numberedList todoList | outdent indent | insertTable | link blockQuote code codeBlock'.split(' '),
                shouldNotGroupWhenFull: true,
            },
            removePlugins: ['MediaEmbedToolbar', 'Markdown'],
            table: ClassicEditor.defaultConfig.table,
        };

        const editorInstance = await ClassicEditor.create(document.querySelector(selector), opt);

        if (content) {
            editorInstance.data.set(content);
        } else {
            editorInstance.data.set('');
        }

        const editorElement = editorInstance.ui.getEditableElement();
        editorElement.style.display = 'block';

        return editorInstance;
    }

    public async switchTab(status: string) {
        this.status = status;
        await this.service.render();
    }
    public async fileUpload(e) {
        console.log(e, '이벤트 타깃 콘솔')
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i]
            this.filename = file.name;
            if (!file.filepath) file.filepath = file.name;
            this.fd.append('file[]', file);
            this.item.files.push(file.filepath);
        }
        await this.service.render();
    }

    public async removeFile(i) {
        this.item.files.splice(i, 1)
    }

    public async removeImage() {
        this.item.featured_image = '';
        await this.service.render();
    }

    public async updateImage() {
        let img = await this.service.file.read({ type: 'image', accept: 'image/*', width: 512, quality: 1 });
        this.item.featured_image = img;
        await this.service.render();
    }

    public async update() {
        if (this.contentEditor) {
            this.item.content = this.contentEditor.getData();
        }

        if (this.item.title.length == 0) {
            await this.alert('제목을 입력해주세요');
            return
        }
        if (this.item.content.length == 0) {
            await this.alert('내용을 입력해주세요');
            return
        }

        this.fd.append('data', JSON.stringify(this.item))

        let url = wiz.url('update')

        const { code, data } = await this.service.file.upload(url, this.fd);
        await this.alert('저장되었습니다', '', 'success', '확인');
        this.service.href('/admin/research/notice')
    }

    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("delete", { id: this.item.id });
        this.service.href(`/admin/research/notice`);
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

}

export default PortalApplyAdminNoticeItemComponent;